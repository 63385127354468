import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { TipsVerde } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import Navegar from '../components/navegar'

// #region GraphQL
export const query = graphql`
  {
    allTipsCelularYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const TipsCelular = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsCelularYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <TipsVerde>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda leyenda="Celular de frente." fluid={toFluid('1-celular')} />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>¿Cómo me comunico?</h2>
                <p>
                  Es un tema que cambia continuamente, de manera que te diría que antes de viajar
                  contactes a tu empresa de celular y preguntes cómo puedes usarlo y sobre todo los
                  costos. Pero yo te voy a contar mi experiencia.
                </p>
                <h3>Usando el celular gratis. </h3>
                <p>
                  Una manera de usar el celular sin costos adicionales es ponerlo en "modo avión".
                  Para comunicarte esperas a encontrarte en zonas con Wi-Fi libre y activas el Wi-Fi
                  de tu celular ya que el "modo avión" lo inactivó automáticamente. Puedes tenerlo
                  así mientras dure tu viaje. Tienes que preguntar el usuario y la clave del Wi-Fi.
                  En Italia usan las palabras en inglés "user" y "password" ¡De última les muestras
                  el celular y adivinan qué quieres! Los hoteles generalmente tienen Wi-Fi libre.
                </p>
                <h3>Te cuento mi experiencia. </h3>
                <p>
                  Hasta allí todo bien. Pero mi experiencia fue que he ido a hoteles donde el Wi-Fi
                  no siempre funcionaba en mi habitación. Me encontraba a los otros turistas fuera
                  de sus habitaciones, sentados en el suelo de los pasillos porque hasta allí
                  llegaba la conexión del Wi-Fi. Una locura. Pero lo peor es cuando tenía que
                  resolver algo urgente y ni el Wi-Fi de la recepción del hotel andaba. Por ejemplo
                  necesitaba hacer de manera urgente el check-in del vuelo y me resultaba imposible.
                  Además tienes que seleccionar las confiterías buscando aquellas que tengan
                  conexión de Wi-Fi ¡Pierdes tu libertad!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  A esto agregale que te gustaría comunicarte por whatsapp en el momento que estás
                  viviendo algo especial o que deseas subir las fotos a Instagram. No tener conexión
                  puede llegar a desesperarte. También por una cuestión de seguridad. Si necesitaras
                  llamar a la policía. Y sin ir tan lejos me pasó que tuve problemas con mis
                  reservas con los hoteles porque no me pude comunicar por teléfono previamente.
                </p>
                <p>
                  Todos esos motivos me llevaron a querer estar siempre comunicada. Sobre todo
                  porque viajo sola. Me siento acompañada contando lo que me sucede en el momento y
                  enviando fotos a mis amigos.
                </p>
                <h3>Consejo para estar siempre comunicado.</h3>
                <p>
                  Después de probar distintas opciones llegué a la conclusión de que para mí lo
                  mejor es comprar una tarjeta chip de una <b>empresa telefónica</b> (más adelante
                  te explico porqué recalco el detalle que debe ser de una empresa telefónica). No
                  lo hago en el mismo aeropuerto porque hay mucha gente y me parece que no tienen
                  las promociones. Espero unos minutos más, lo que dura mi viaje en tren hasta Roma.
                  Al llegar a la estación terminal de trenes de Roma, Termini, voy a Euronics, que
                  se encuentra en el subsuelo, y veo si tienen las promociones de Vodafone. Es
                  notable porque a unos metros está el local de Vodafone pero ¡NO TIENEN LAS
                  PROMOCIONES! es muy extraño. Esas promociones son para turistas. La que uso me
                  permite hablar 300 minutos a Argentina, que para mí es importante porque mi mamá
                  no maneja whatsapp. Además 500 minutos a cualquier lugar de Europa. Eso es lo que
                  uso para comunicarme con los hoteles. Y 5Gb de Internet. Y cuando he viajado
                  dentro de Europa he seguido conectada.
                </p>
                <h3>No te olvides de llevar el pasaporte.</h3>
                <p>
                  Tienes que llevar el pasaporte para comprar la tarjeta chip. Por eso es una buena
                  idea comprarla en cuanto llegas a Roma ya que estás con el pasaporte encima. Y
                  tienes que tener siempre contigo la herramienta para abrir la bandejita del
                  celular para poder colocar la SIM. Me refiero a esas herramientas que te muestro
                  en la foto. Sino ¿qué puedes usar para abrir la bandejita? A mí no se me ocurre
                  nada de nada.
                </p>
                <p>
                  ¡Y me dieron un número de teléfono italiano! Igual puedes elegir mantener el
                  número argentino para whatsapp ¿Sabes qué hice yo? Le puse una tarjeta también a
                  la tablet, no sea que me quede incomunicada, ja, ja. Soy una exagerada.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda leyenda="Tarjetas sim." fluid={toFluid('2-sim')} />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                leyenda="Distintas herramientas para abrir la bandejita para la sim."
                fluid={toFluid('3-herramientas')}
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  Te hablé de Vodafone porque es la que tengo pero hoy por hoy creo que usaría otra
                  compañia. Investigaría un poco cómo anda Tim. En Vodafone me atendieron
                  generalmente muy mal y me hicieron perder mucho tiempo y dinero por informarme
                  mal. Hay detalles que tienes que saber, claro que para el caso en que realices un
                  viaje de más de un mes o que esteis pensando en volver pronto a Europa. Por
                  ejemplo, yo debía hacer la recarga el día 22. No lo sabía, fui a hacer la recarga
                  el 18 y a los cuatro días me quedé sin conexión ¿cómo? porque vencía el 22. Son
                  estafadores!!! Tuve que volver a pagar.
                </p>
                <p>
                  De todas maneras pregúntale a tu proveedor de celular y además porque hay nuevas
                  opciones. Por ejemplo en Claro me habían dicho que podía hacer llamadas
                  internacionales como si fueran locales bajándome una aplicación. A mí no me
                  funcionó y no tenía a quien preguntarle cómo se usaba. Pero es cuestión de
                  averiguar.
                </p>
                <p>
                  Me cansé de depender del Wi-Fi. Ahora tengo internet todo el tiempo y soy feliz.
                </p>
                <p>
                  Te cuento esto porque antes compraba tarjetas chips que parecían más baratas que
                  las de las compañias telefónicas pero las podía usar sólo en Italia y además nunca
                  supe por qué cada tanto tenía problemas y me quedaba sin conexión.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  Teóricamente me duraban un mes y después pagaba sólo la recarga que podía hacer yo
                  misma por internet. Terminé teniendo que volver a pagar todo y nunca entendí
                  porqué. La que usaba era Lycamobile. Costaba 5 euros el chip y 5 euros la carga
                  mínima. Pero las últimas veces me dijeron 10 euros cada cosa. Con crédito para 1
                  Gb durante un mes. Y me quedé sin crédito a los 10 días!!! Iba al negocio donde la
                  había comprado y no sé qué hacían con lo datos que había que habilitar??? ¿cómo?
                  si ya la estaba usando. Eran indios (son como nuestros chinos), no les tenía
                  ninguna confianza. Siempre con problemas. Y si no estaba en Roma no tenía a quien
                  recurrir ni cómo recargarla (no sabía lo de la recarga por Internet). Por eso
                  prefiero una compañia de celulares que tiene cobertura en todos lados y te las
                  recargan en las tabaquerías si no quieres usar Internet.
                </p>
                <h3>Consejo y anécdota.</h3>
                <p>
                  Cuando cambies el chip del celular fíjate dónde es el mejor lugar para guardar el
                  chip argentino. Tiene que estar en un lugar seguro pero muy a mano para cuando
                  llegues a Ezeiza, si es que no quieres quedarte incomunicado en Argentina.
                </p>
                <p>
                  Otro detalle, súper importante. Tienes que tener a mano el número de tu tarjeta
                  SIM, sino no vas a poder habilitarla nuevamente. Cuando apagas un dispositivo,
                  celular o tablet, y luego lo enciendes te pide la clave de la tarjeta SIM. Si no
                  la tienes ¡Horror! te quedas sin celular!!!
                </p>
                <h3>Y se viene la anécdota.</h3>
                <p>
                  Me ocurrió que cuando llegué a Ezeiza y necesitaba comunicarme de manera urgente y
                  pensé que con el chip de Italia y el Wi-Fi del aeropuerto lo iba a poder hacer
                  (sabía que no, pero, quién sabe porqué pensaba que sí). Pero no, el chip italiano,
                  por lo menos el de Lycamobile, era sólo para Italia. Entré en pánico ¿dónde
                  diablos habré guardado la SIM de Claro? Por suerte estaba donde se suponía que
                  tenía que estar. Y, ahí vino el otro problema ¿dónde la coloco? Esto me ocurrió
                  hace unos años, con ese celular viejo la manera de cambiar el chip era abriendo el
                  celular. Por las dudas averigua primero dónde va el chip. Logré abrir el celular,
                  no era tan fácil. Veía la tarjeta de memoria que le había agregado pero…¿el chip,
                  dónde diablos iba? ¡al fin! Puedes creer que estaba debajo de la batería!!! No
                  creas que no sufrí!!!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                leyenda="Agujerito en el costado del celular para abrir la bandeja de la sim."
                fluid={toFluid('4-celularDePerfil')}
              />
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                leyenda="Celular con la bandeja abierta para la sim."
                fluid={toFluid('5-celularYtarjeta')}
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  Bueno, el corazón casi se me detiene! ja, ja. Para no sufrir en vano solo tienes
                  que recordar eso y tener el chip a mano. No sea que esté en alguna valija!!! Yo
                  pude solucionar todo mientras hacía la cola de migraciones. Pero ahora, más
                  organizada cambio el chip cuando aún estoy en el avión. Tiene en cuenta de tener a
                  mano también la herramienta para abrir la bandejita donde va el chip.
                </p>
                <h3>Mi experiencia con los Gbs.</h3>
                <p>
                  ¿Cuántos Gb le cargo de crédito? Habitualmente cargaba 1 Gb y casi siempre me
                  duraba todo el mes sin problemas. Pero una vez exageré con las fotos que envié por
                  whatsapp y que subí a Google Fotos y a Instagram me quedé sin crédito a los 10
                  días. Creo que si piensas que le vas a dar un uso así de intensivo es mejor que
                  cargues 2 Gb por 7 euros o 4 Gb por 10 euros (son los precios de las tarjetas tipo
                  Lycamobile). Por algo las compañias telefónicas te dan 5 Gb! Si la tarjeta es del
                  tipo de Lycamobile entonces es mejor que la compres en Roma y que allí mismo
                  cargues el crédito. Allí tienen de todo y saben ayudarte. Comprobé que en otros
                  lugares turísticos, como Taormina, ¡no existen estas tarjetas! Ni saben cargarlas.
                  Tuve que ir al único lugar de Giardini di Naxos en el cual hacían la carga pero el
                  muchacho que me atendió me trató pésimo y como él no sabía nada de nada ¡no pude
                  usar el crédito!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3> Porqué quiero estar siempre conectada. </h3>
                <p>Te había adelantado algo pero tengo aún más razones.</p>
                <p>
                  Uso el GPS de Google. Me salvó en varias oportunidades y en dos me arruinó, ja,
                  ja. Pero más son las veces que me salvó. Será tema de otro post.
                </p>
                <p>
                  Es muy importante sobre todo cuando se anda en auto. No solamente el GPS sino
                  tener la oportunidad de pedir auxilio por cualquier inconveniente que surgiera con
                  el auto.
                </p>
                <p>
                  También uso la aplicación "Fit", me encanta. Me dice cuánto caminé en tiempo y en
                  distancia, cuántas calorías gasté y me muestra los mapas con mis recorridos,
                  siempre y cuando esté conectada a Internet.
                </p>
                <p>
                  Y, por lo que te conté de compartir las fotos al instante en whatsapp o en
                  Instagram.
                </p>
                <p> Post, junio 2019. </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda leyenda="Una tarjeta sim." fluid={toFluid('6-sim')} />
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </TipsVerde>
    </Marco>
  )
}
export default TipsCelular

